.category-text{
    padding: 48px 0 96px;

    h2{
        text-align: center;
        margin-bottom: 32px;
    }

    &-items{
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        row-gap: 32px;
        justify-content: center;
        max-width: 700px;
        margin: auto;

        @include mq(false, md) {
            gap: 10px;
            row-gap: 20px;
        }
    }

    &-btn{
        position: relative;
        display: inline-flex;
        padding: 14px 32px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #FFF;
        color: #000;
        font-size: 16px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 0.32px;
        overflow: hidden;
    }

    &-btn-hover{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: #000;
        padding-left: 16px;
        padding-right: 10px;
        color: #fff;
        opacity: 0;
        .icon{
            height: 20px;
            width: 20px;
            stroke: $accent;
            stroke-width: 1.5px;
        }
    }

    &-btn:hover{
        border: 1px solid #000;
        .category-text-btn-hover{
            opacity: 1;
        }
    }
    // @include mq(false, lg) {
    //     gap: 30px;
    // }
}


