.page-product-single{
    .catalog-cards{
        padding-top: 70px;
        padding-bottom: 128px;
        background: #fff;
        &-btn{
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include mq(false, md) {
                flex-direction: column;
                gap: 20px;
            }

            .btn-accent{
                margin-top: 0;
            }
            .btn-light,
            .btn-accent{
                min-width: 230px;

                @include mq(false, md) {
                    width: 100%;
                }
            }
            .btn-light{
                background: #F4F5F6;
            }
        }
        &-cat{
            margin-bottom: 88px;

            @include mq(false, md) {
                margin-bottom: 50px;
            }

        }
    }
    .catalog-cards-title{
        margin-bottom: 72px;
        @include mq(false, md) {
            margin-bottom: 40px;
        }
    }
}


.product-single{
    &-grid{
        padding-bottom: 112px;
        .container{
            display: flex;
            justify-content: space-between;
            gap: 76px;
            @include mq(false, lg) {
                flex-direction: column;
                gap: 32px;
            }
        }
    }
    &-col{
        &.-gallery{
            width: 50%;
            @include mq(false, lg) {
                width: 100%;
            }
        }
        &.-info{
            width: 50%;
            @include mq(false, lg) {
                width: 100%;
            }
        }
    
        .product-info{
            &-light{
                margin-top: 10px;
                p{
                    color: $black;
                    font-family: $secondary-font;
                    font-size: 16px;
                    font-weight: 400;
                    opacity: .5;
                    margin-bottom: 0 !important;
                    b{
                        font-weight: 500;
                    }
                }
            }
            &-title{
                margin-top: 18px;
                text-transform: uppercase;
            }
            &-price{
                margin-top: 18px;
                color: $accent;
                font-family: $secondary-font;
                font-size: 30px;
                font-weight: 400;
            }
            &-light.-line{
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
            }
            &-main{
                margin-top: 18px;
                p{
                    font-size: 18px;
                }
            }
            &-btn{
                margin-top: 32px;
                margin-bottom: 48px;
                display: flex;
                gap: 16px;
                align-items: center;
            }
            &-desc{
                margin-top: 32px;
                padding-top: 32px;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                h5{
                    margin-bottom: 15px;
                    font-size: 18px;
                    font-family: $main-font;
                }
                .table{
                    .table-row{
                        display: flex;
                    }
                    .table-col{
                        padding: 12px;
                        width: 50%;
                    }
                }
            }
        }
    }
    // @include mq(false, lg) {
    //     gap: 30px;
    // }
}



.swiper {
    width: 100%;
    height: auto;
}

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .product-single-slider {
    height: auto;
    width: 100%;
  }

  .product-single-slider-thumbs {
    height: auto;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .product-single-slider-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: .7;
    border-radius: 4px;
  }

  .product-single-slider-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .swiper-button-prev:after, .swiper-button-next:after{
    content: none;
  }

  .swiper-button-prev,
  .swiper-button-next{
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    border-radius: 40px;
    background: rgba(0, 0, 0, 0.30);
    .icon{
        height: 26px;
        width: 26px;
        stroke: #fff;
        stroke-width: 1.5px;
    }
    &:hover{
        background: rgba(0, 0, 0, 0.50);
    }
  }

  .swiper-button-prev{
    .icon{
        transform: rotate(180deg);
    }
  }