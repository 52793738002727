.articul-block{
    padding-top: 32px;
    padding-bottom: 16px;
    .articul{
        justify-content: end;
    }
}

.articul{
    display: flex;
    align-items: center;
    gap: 16px;
    
    @include mq(false, sm) {
        gap: 10px;
    }
    
    .icon{
        stroke: $black;
        stroke-width: 1.5px;
        height: 20px;
        width: 20px;
        opacity: .5;
    }
    &-btn{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        gap: 8px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        border-radius: 4px;
        min-width: 40px;
        span{
            color: #000;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
            opacity: 0.5;
        }
        &.-prev{
            .icon{
                transform: rotate(-180deg);
            }
        }
        &.-next,
        &.-prev{
            background: #fff;
        }
        &.-next:hover,
        &.-prev:hover{
            opacity: .85;
        }
    }
    
    // @include mq(false, lg) {
    //     gap: 30px;
    // }
}


