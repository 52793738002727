.page-head{
    padding-top: 48px;
    padding-bottom: 72px;

    h1{
        text-transform: uppercase;
    }

    @include mq(false, sm) {
        padding-top: 20px;
        padding-bottom: 48px;
        h1{
            font-size: 36px;
        }
    }

    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        row-gap: 20px;

        &.-center{
            justify-content: center;
        }

        @include mq(false, lg) {
            flex-wrap: wrap;
        }
    }
    .item-start,
    .item-end{
        min-width: 250px;
    }
    .item-start{
        p{
            margin-top: 5px;
            margin-bottom: 0;
        }
        @include mq(false, lg) {
            order: 2;
        }
        @include mq(false, sm) {
            width: 100%;
        }
    }
    .item-end{
        display: flex;
        justify-content: end;
        flex-shrink: 0;
        gap: 12px;
        @include mq(false, lg) {
            order: 3;
        }
        @include mq(false, sm) {
            width: 100%;
        }
    }

    .item-center{
        text-align: center;
        justify-content: center;
        @include mq(false, lg) {
            text-align: left;
            width: 100%;
            order: 1;
        }
    }
    
    .dropdown,
    .btn-accent,
    .btn-light{
        @include mq(false, sm) {
            width: 100%;
        }
    }

}


