.input-count{
    position: relative;
    max-width: 115px;
    input{
        border-radius: 4px;
        background: #FFF;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        height: 52px;
        padding: 0px 12px;
        align-items: center;
        width: 100%;
        font-weight: 800;
        color: $black;
    }
    .icon{
        height: 24px;
        width: 24px;
        stroke: $black;
        opacity: .5;
        stroke-width: 2px;
    }

    &-btn{
        height: 52px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        &.-minus{
            left: 0;
        }
        &.-plus{
            right: 0;
        }
        &:hover{
            .icon{
                opacity: 1;
            }
        }
    }
    // @include mq(false, lg) {
    //     gap: 30px;
    // }
}


