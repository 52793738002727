.header-top{
    padding: 8px 0;
    background: $black;
    position: relative;
    z-index: 8888;
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

   &-nav{
        list-style: none;
        li{
            display: inline-block;
            margin: 0 12px;
            a{
                letter-spacing: 0.2px;
                color: #fff !important;
                opacity: .5;
            }
            a:hover{
                opacity: 1;
            }
        }

        @include mq(false, lg) {
            display: none;
        }
    }

    &-contacts{
        display: flex;
        align-items: center;
        gap: 24px;

        @include mq(false, md) {
            flex-direction: row-reverse;
            gap: 16px;
        }
    }

    &-contact{
        a{
            font-size: 13px;
            color: #fff;
            display: flex;
            align-items: center;
            gap: 4px;
            letter-spacing: 0.2px;

            &:hover{
                @include hover-opacity;
            }

            span:last-child{
                display: none;
            }

            @include mq(false, md) {
                span:nth-child(2){
                    display: none;
                }
                span:last-child{
                    display: block;
                }
            }
        }
        .icon{
            height: 14px;
            width: 14px;
        }
    }
}

.header-main{
    padding: 24px 0;
    background: $black;

    @include mq(false, sm) {
        padding-top: 10px;
        padding-bottom: 15px;
    }

    .container{
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 24px;
    }

    &-logo{
        display: block;
        img{
            max-width: 150px;
            max-height: 60px;
        }

        @include mq(false, sm) {
            img{
                max-width: 122px;
                max-height: 42px;
            }
        }
    }

    &-center{
        display: flex;
        align-items: center;
        gap: 24px;

        @include mq(false, lg) {
            display: none;
        }
    }

    &-dropdown{
        flex-shrink: 0;
        .dropdown-btn{
            display: flex;
            height: 52px;
            padding: 16px 24px 16px 16px;
            align-items: center;
            gap: 16px;
            border-radius: 4px;
            background: #FFF;
            list-style: 100%;

            &:hover{
                opacity: .9;
            }

            .icon-box{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 22px;
                width: 22px;
                background: $accent;
                border-radius: 30px;
                .icon{
                    height: 20px;
                    width: 20px;
                    stroke: #fff;
                }
            }
            .icon-title{
                color: #000;
                font-family: $secondary-font;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.18px;
            }
        }
    }

    &-search{
        max-width: 100%;
        width: 520px;
        @include mq(false, xl) {
            max-width: 100%;
            width: 380px;
        }
    }

    &-buttons{
        display: flex;
        align-items: center;
    }

    &-btn{
        display: flex;
        align-items: center;
        padding: 12px;
        &:hover{
            @include hover-opacity;
        }
        .icon{
            height: 24px;
            width: 24px;
            stroke: #fff;
        }
        .icon-search{
            stroke-width: 1.5px;
        }

        &.-cart{
            position: relative;
            .count{
                position: absolute;
                top: 6px;
                right: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 18px;
                width: 18px;
                border-radius: 20px;
                background: $accent;
                color: #FFF;
                font-family: $secondary-font;
                font-size: 14px;
                font-weight: 600;
            }
        }

        &.-search{
            display: none;

            @include mq(false, lg) {
                display: flex;
            }
        }

        &.-menu{
            display: none;
            position: relative;
            z-index: 8888;

            @include mq(false, lg) {
                display: flex;
            }
        }
    }

}

