@use "sass:math";
@use "sass:map";
@use "sass:list";

$breakpoints: (
    mobile: 320px,
    tablet: 740px,
    desktop: 980px,
    wide: 1300px,
) !default;

$show-breakpoints: () !default;

$media-type: all !default;


/// Convert pixels to ems
@function px2em($px) {
    // @if math.is-unitless($px) {
    //     @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels.";
    //     @return px2em($px * 1px);
    // }
    // // if $px is compatible with em units then return value unchanged
    // @if math.compatible($px, 1em) {
    //     @return $px;
    // }
    // @return math.div($px, 16px) * 1em;
    @return $px;
}

// Get a breakpoint's width
//
// @example scss
// $tablet-width: get-breakpoint-width(tablet);
// @media (min-width: get-breakpoint-width(desktop)) {}
@function get-breakpoint-width($name, $breakpoints: $breakpoints) {
    @if map.has-key($breakpoints, $name) {
        @return map.get($breakpoints, $name);
    } @else {
        @warn "Breakpoint #{$name} wasn't found in $breakpoints.";
        @return null;
    }
}

/// Media Query mixin
///
/// @example scss
///  .element {
///    @include mq($from: mobile) {
///      color: red;
///    }
///    @include mq($until: tablet) {
///      color: blue;
///    }
///    @include mq(mobile, tablet) {
///      color: green;
///    }
///    @include mq($from: tablet, $and: '(orientation: landscape)') {
///      color: teal;
///    }
///    @include mq(950px) {
///      color: hotpink;
///    }
///    @include mq(tablet, $media-type: screen) {
///      color: hotpink;
///    }
///    // Advanced use:
///    $my-breakpoints: (L: 900px, XL: 1200px);
///    @include mq(L, $breakpoints: $my-breakpoints) {
///      color: hotpink;
///    }
///  }
@mixin mq(
    $from: false,
    $until: false,
    $and: false,
    $media-type: $media-type,
    $breakpoints: $breakpoints
) {
    $min-width: 0;
    $max-width: 0;
    $media-query: "";

    // From: this breakpoint (inclusive)
    @if $from {
        @if type-of($from) == number {
            $min-width: px2em($from);
        } @else {
            $min-width: px2em(get-breakpoint-width($from, $breakpoints));
        }
    }

    // Until: that breakpoint (exclusive)
    @if $until {
        @if type-of($until) == number {
            $max-width: px2em($until);
        } @else {
            // $max-width: px2em(get-breakpoint-width($until, $breakpoints)) - 0.01em;
            $max-width: px2em(get-breakpoint-width($until, $breakpoints)) - 1px;
        }
    }

    @if $min-width != 0 {
        $media-query: "#{$media-query} and (min-width: #{$min-width})";
    }
    @if $max-width != 0 {
        $media-query: "#{$media-query} and (max-width: #{$max-width})";
    }
    @if $and {
        $media-query: "#{$media-query} and #{$and}";
    }

    // Remove unnecessary media query prefix 'all and '
    @if ($media-type == "all" and $media-query != "") {
        $media-type: "";
        $media-query: str-slice(unquote($media-query), 6);
    }

    @media #{$media-type + $media-query} {
        @content;
    }
}

/// Quick sort
@function _quick-sort($list) {
    $less: ();
    $equal: ();
    $large: ();

    @if length($list) > 1 {
        $seed: list.nth($list, math.ceil(math.div(length($list), 2)));

        @each $item in $list {
            @if ($item == $seed) {
                $equal: list.append($equal, $item);
            } @else if ($item < $seed) {
                $less: list.append($less, $item);
            } @else if ($item > $seed) {
                $large: list.append($large, $item);
            }
        }

        @return join(join(_quick-sort($less), $equal), _quick-sort($large));
    }

    @return $list;
}

/// Sort a map by values (works with numbers only)
@function _map-sort-by-value($map) {
    $map-sorted: ();
    $map-keys: map.keys($map);
    $map-values: map.values($map);
    $map-values-sorted: _quick-sort($map-values);

    // Reorder key/value pairs based on key values
    @each $value in $map-values-sorted {
        $index: index($map-values, $value);
        $key: list.nth($map-keys, $index);
        $map-sorted: map.merge(
            $map-sorted,
            (
                $key: $value,
            )
        );

        // Unset the value in $map-values to prevent the loop
        // from finding the same index twice
        $map-values: list.set-nth($map-values, $index, 0);
    }

    @return $map-sorted;
}

/// Add a breakpoint
///
/// @example scss
///  @include add-breakpoint(tvscreen, 1920px);
///  @include mq(tvscreen) {}
@mixin add-breakpoint($name, $width) {
    $new-breakpoint: (
        $name: $width,
    );
    $breakpoints: map.merge($breakpoints, $new-breakpoint) !global;
    $breakpoints: _map-sort-by-value($breakpoints) !global;
}

/// Show the active breakpoint in the top right corner of the viewport
/// 
/// @example scss
///  // Show breakpoints using global settings
///  @include show-breakpoints;
///
///  // Show breakpoints using custom settings
///  @include show-breakpoints((L, XL), (S: 300px, L: 800px, XL: 1200px));
@mixin show-breakpoints(
    $show-breakpoints: $show-breakpoints,
    $breakpoints: $breakpoints
) {
    body:before {
        background-color: #fcf8e3;
        border-bottom: 1px solid #fbeed5;
        border-left: 1px solid #fbeed5;
        color: #c09853;
        font: small-caption;
        padding: 3px 6px;
        pointer-events: none;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 100;

        // Loop through the breakpoints that should be shown
        @each $show-breakpoint in $show-breakpoints {
            $width: get-breakpoint-width($show-breakpoint, $breakpoints);
            @include mq($show-breakpoint, $breakpoints: $breakpoints) {
                content: "#{$show-breakpoint} ≥ #{$width} (#{px2em($width)})";
            }
        }
    }
}

@if list.length($show-breakpoints) > 0 {
    @include show-breakpoints;
}
