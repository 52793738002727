.btn-accent{
    display: inline-flex;
    padding: 14px 24px 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #FE2D37;
    color: #F4F5F6;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.16px;

    &:hover{
        opacity: .95;
    }

    .icon.icon-plus02{
        stroke-width: 2px;
        height: 26px;
        width: 26px;
        margin-right: -4px;
    }

    .icon{
        height: 20px;
        width: 20px;
        stroke: #fff;
        stroke-width: 1.5px;
    }
    .icon-box{
        display: flex;
        align-items: center;
    }

    &.-icon-right{
        padding-right: 20px;
    }

    &.-icon-left{
        padding-left: 20px;
    }

    &.-full{
        width: 100%;
    }
}

.btn-light{
    display: inline-flex;
    padding: 14px 24px 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    color: $black;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.16px;
    border: 1px solid rgba(0, 0, 0, 0.10);

    @include mq(false, md) {
        padding: 12px 16px 12px 16px;
        gap: 4px;
    }
       

    &:hover{
        border: 1px solid rgba(0, 0, 0, 0.20);
    }

    &.-icon-right{
        padding-right: 20px;
        @include mq(false, md) {
            padding-right: 5px;
        }
    }

    &.-icon-left{
        padding-left: 20px;
        @include mq(false, md) {
            padding-left: 8px;
        }
    }
    
    .icon{
        height: 20px;
        width: 20px;
        stroke: $accent;
        stroke-width: 1.5px;
    }
    .icon-box{
        display: flex;
        align-items: center;
    }
}

.btn-outline{
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    color: $accent;
    border-bottom: 1px solid $accent;

    &:hover{
        opacity: .85;
    }
}

// .btn{
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 100px;
//     padding: 13px 40px;
//     @include paragraph-md;
//     font-weight: 900;
//     cursor: pointer;
//     transition: 0.2s;
//     color: $gray;
//     gap: 16px;

//     @include mq(false, sm) {
//         width: 100%;
//     }

//     &.-disabled{
//         pointer-events: none;
//     }

//     &.-lg{
//         @include paragraph-lg;
//         padding: 16px 48px;

//         svg{
//             --size: 24px;
//         }
//     }

//     &.-accent{
//         background: $accent;

//         @include hover {
//             background: $white;

//             svg{
//                 fill: $accent;
//             }
//         }

//         &.-disabled{
//             background: #C8C3B9;

//             svg{
//                 fill: rgba($gray, 0.5);
//             }
//         }

//         svg{
//             fill: $gray;
//         }
//     }

//     &.-white{
//         background: $white;

//         @include hover {
//             background: $accent;

//             svg{
//                 fill: $gray;
//             }
//         }

//         svg{
//             fill: $accent;
//         }
//     }

//     svg{
//         --size: 16px;
//         width: var(--size);
//         height: var(--size);
//         min-width: var(--size);
//         transition: 0.2s;

//         &:nth-child(1){
//             margin-left: -8px;
//         }
//     }
// }