.breadcrumbs{
    ul{
        li{
            display: inline-block;
            a{
                font-size: 16px;
                font-family: $secondary-font;
                color: $black;
                opacity: .5;
            }
            a:hover{
                opacity: 1;
            }
            span{
                font-size: 16px;
                font-family: $secondary-font;
            }
        }
    }
    .icon{
        position: relative;
        top: 3px;
        stroke: $black;
        stroke-width: 1.5px;
        height: 18px;
        width: 18px;
        opacity: .5;
    }
    // @include mq(false, lg) {
    //     gap: 30px;
    // }
}


