.subheader{
    padding: 17px 0;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);

    @include mq(false, sm) {
        padding: 6px 0;
    }

    .container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 72px;
        @include mq(false, lg) {
            gap: 30px;
        }
        @include mq(false, md) {
            flex-direction: column;
            align-items: start;
            gap: 15px;
        }
    }
    .item{
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .item-whatsapp{
        p{
            font-size: 16px;
            font-family: $secondary-font;
            color: $black;
            opacity: .5;
            font-family: $secondary-font;
            margin-bottom: 0 !important;
        }

        @include mq(false, lg) {
            display: none;
        }
    }

}