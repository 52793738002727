/**
 * Grid
 */
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1170px,
    xxl: 1300px
);

$breakpoints: $grid-breakpoints;

@use "./mq" with(
    $breakpoints: $breakpoints, 
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1300px
);

$grid-columns: 12;
$grid-gutter-width: 30px;

/**
 * Fonts
 */
$main-font: 'Roboto';
$secondary-font: 'Roboto Condensed'; 

/**
 * Color Palette
 */
$white: #fff;
$black: #000000;
$accent: #FE2D37;
$dark: #000000;
$gray: #4C4C4C;
$flesh: #F4F5F6;