.product-card{
    position: relative;
    height: 140px;
    width: 100%;
    border-radius: 4px;
    background-size: cover;
    background-position: center;

    &:hover{
        .product-card-hover{
            opacity: 1;
        }
    }

    &-hover{
        display: flex;
        gap: 8px;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.85);
        border-radius: 4px;
        z-index: 2;
        padding: 12px;
        opacity: 0;
    }

    &-link{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
    }

    &-title{
        color: #F4F5F6;
        font-family: $secondary-font;
        font-size: 18px;
        line-height: 130%;
        font-weight: 400;
    }
    &-price{
        color: #F4F5F6;
        font-family: $secondary-font;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.36px;
    }

    // @include mq(false, lg) {
    //     gap: 30px;
    // }
}

.product-card02{
    position: relative;
    img{
        width: 100%;
        height: auto;
        border-radius: 4px;
    }
    &-link{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    &-info{
        padding: 10px 0;
    }
    &-title{
        font-weight: 400;
    }
    &-price{
        margin-top: 10px;
        font-weight: 500;
        font-size: 18px;
    }
}
