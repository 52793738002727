.catalog-cards{
    padding-bottom: 72px;
    @include mq(false, sm) {
        padding-bottom: 48px;
    }

    &-row{
        display: flex;
        gap: 32px;

        @include mq(false, lg) {
            flex-direction: column;
        }

        .catalog-sidebar{
            min-width: 230px;
            flex-shrink: 0;
            @include mq(false, lg) {
                width: 100%;
            }
        }
        .catalog-cards-products{
            width: 100%;
        }
    }

    &-cat{
        margin-bottom: 56px;
        @include mq(false, sm) {
            margin-bottom: 48px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }

    &-title{
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        gap: 8px;
        span{
            font-size: 30px;
            display: inline-flex;
        }
        span,
        .-light{
            font-weight: 400;
        }
        a{
            display: inline-flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            .icon{
                position: relative;
                top: 1px;
                stroke: $black;
                stroke-width: 2px;
                height: 26px;
                width: 26px;
                opacity: 0;
            }
        }
        a:hover{
            color: $accent; 
            .icon{
                stroke: $accent;
                opacity: 1;
            }
        }
    }
    
    &-products{
        display: grid;
        grid-template-rows: 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        justify-content: center;

        &.-grid-6{
            grid-template-columns: repeat(6, 1fr);
        }

        &.-grid-5{
            grid-template-columns: repeat(5, 1fr);
        }

        &.-grid-4{
            grid-template-columns: repeat(5, 1fr);
        }

        &.-grid-6,
        &.-grid-5,
        &.-grid-4 {

            @include mq(false, xl) {
                grid-template-columns: repeat(4, 1fr);
            }

            @include mq(false, lg) {
                grid-template-columns: repeat(3, 1fr);
            }

            @include mq(false, md) {
                grid-template-columns: repeat(2, 1fr);
            }

        }
    }

    .btn-accent{
        margin-top: 24px;
        gap: 2px;
        .icon-plus{
            margin-right: 6px;
            stroke-width: 0;
        }
    }
}

.catalog-products{
    width: 100%;
}

.catalog-pagination{
    padding-top: 24px;
    padding-bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    p{
        width: auto;
        color: #000;
        font-family: $secondary-font;
        font-size: 18px;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 0 !important;
    }
    .pagination{
        width: auto;
        flex-shrink: 0;
    }
}