.line-icons{
    padding: 17px 0;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    &.-grey{
        background: #F4F5F6;;
        border-bottom: none;
    }
    .container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 72px;
        @include mq(false, lg) {
            gap: 30px;
        }
        @include mq(false, md) {
            flex-direction: column;
            align-items: start;
            gap: 15px;
        }
    }
    .item{
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .icon{
        height: 18px;
        width: 18px;
    }
    p{
        font-size: 16px;
        letter-spacing: 0.2px;
        font-family: $secondary-font;
    }
}