/**
 * Typography 
 */

// Headings
@mixin heading-style {
    line-height: 120%;
    font-family: $secondary-font;
    font-weight: 600;
    color: $black;
}

@mixin heading-1 {
    @include heading-style;
    font-size: 44px;

    @include mq(false, md) {
        font-size: 38px;
    }
}

@mixin heading-2 {
    @include heading-style;
    font-size: 30px;

    @include mq(false, md) {
        font-size: 24px;
    }
}

@mixin heading-3 {
    @include heading-style;
    font-size: 21px;

    @include mq(false, md) {
        font-size: 20px;
    }
}

@mixin heading-4 {
    @include heading-style;
    font-size: 18px;

    @include mq(false, md) {
        font-size: 18px;
    }
}

@mixin heading-5 {
    @include heading-style;
    font-size: 16px;

    @include mq(false, md) {
        font-size: 16px;
    }
}

@mixin heading-6 {
    @include heading-style;
    font-size: 14px;

    @include mq(false, md) {
        font-size: 14px;      
    }
}

// Paragraphs
@mixin paragraph-style {
    line-height: 150%;
}

@mixin paragraph-xl {
    @include paragraph-style;
    font-size: 21px;

    @include mq(false, md) {
        font-size: 20px;
    }
}

@mixin paragraph-lg {
    @include paragraph-style;
    font-size: 18px;
    line-height: 28px;
}

@mixin paragraph-md {
    @include paragraph-style;
    font-size: 16px;
}

@mixin paragraph-sm {
    @include paragraph-style;
    font-size: 14px;
    line-height: 22px;
}

/**
 * Hover
 */
@mixin hover-opacity {
    opacity: .85;
}

@mixin hover {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }
}