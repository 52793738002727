#nav-toggle {
    position: relative;
    background: transparent;
    box-sizing: border-box;
    z-index: 8888;
    width: 24px;
    height: 24px; 
    
    &.active span {
        background-color: transparent !important;
    }
    &.active span:before,
    &.active span:after {
        top: 0;
    }
    &.active span:before {
        width: 22px;
        transform: rotate(45deg);
        background: #fff;
    }
    &.active span:after {
        transform: rotate(-45deg);
        width: 22px;
        background: #fff;
    }
    span,
    span:before,
    span:after {
        cursor: pointer;
        height: 1.5px;
        width: 22px;
        background: #fff;
        position: absolute;
        right: 0;
        top: 50%;
        display: block;
        content: '';
        z-index: 9999;
        transition: 0.2s;
    }
    span:before {
        top: -7px;
    }
    span:after {
        top: 7px;
        width: 11px;
    }
}

.menu-tel.in{
    left: 0;
}

.menu-tel{
    display: flex;
    position: fixed;
    left: -100%;
    width: 100%;
    right: auto;
    top: 0;
    bottom: 0;
    background: $black;
    z-index: 8880;
    transition: 0.5s;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow-y: auto;
    padding: 132px 20px 40px 20px; 

    @include mq(false, sm) {
        padding: 100px 20px 20px 20px; 
    }

    &-content{
        width: 100%;
        .container{
            display: flex;
            flex-direction: column;
            gap: 100px;
        }
    }
    &-title{
        color: #FFF;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        opacity: .4;
        margin-bottom: 20px;
        line-height: 110%;
    }
    .nav-list{
        li{
            a{
                display: block;
                padding: 12px 0;
                width: 100%;
                color: #FFF;
                font-size: 21px;
                font-weight: 400;
                line-height: 120%;
            }
            &.current-menu-item{
                a{
                    color: $accent;
                }
            }
        }
        &.-col{
            display: flex;
            flex-wrap: wrap;
            max-width: 400px;
            li{
                width: 50%;
            }
        }
    }
}