.hero{
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    padding-top: 96px;
    padding-bottom: 120px;
    height: 60vh;
    min-height: 400px;
    max-height: 600px;
    &-content{
        text-align: center;
        h1{
            color: #fff;
        }
        p{
            margin-top: 16px;
            margin-bottom: 0;
            font-size: 18px;
            color: #fff;
        }
    }
    .search{
        max-width: 450px;
        margin: auto;
        margin-top: 32px;
    }
}