html,
body{
    min-height: 100svh;
}

body.no-scroll{
    overflow: hidden;
}

body {
    font-family: $main-font;
    color: $dark;
    display: flex;
    flex-direction: column;
    background: #F4F5F6;
}

::placeholder {
  	color: rgba($dark, 0.3);
}

.tel{
    display: none;
    @include mq(false, sm) {
        display: block;
    }
}

.desc{
    @include mq(false, sm) {
        display: none;
    }
}

section{
    padding: 72px 0;
    
    @include mq(false, md) {
        padding: 40px 0;       
    }
}


h1,
.h1{
    @include heading-1;
}

h2,
.h2{
    @include heading-2;
}

h3,
.h3{
    @include heading-3;
}

h4,
.h4{
    @include heading-4;
}

h5,
.h5{
    @include heading-5;
}

h6,
.h6{
    @include heading-6;
}

p{
    @include paragraph-md;

    &:not(:last-child){
        margin-bottom: 16px;

        @include mq(false, md) {
            margin-bottom: 12px;
        }
    }

    a{
        color: $accent;
        text-decoration: underline;

        &:hover{
            text-decoration: none;
        }
    }
}

.-bg-gray{
    background: $gray;
}

input[type="checkbox"] {
    height: 16px;
    width: 16px;
    display: block;
    appearance: auto;
    accent-color: $accent;
}