.text-img{
    background: #fff;
    padding: 112px 0;

    .row{
        align-items: center;
        padding-left: 80px;
        padding-right: 80px;

        @include mq(false, xl) {
            padding-left: 0;
            padding-right: 0;
        }

    }

    .item-img{
        img{
            width: 100%;
            height: auto;
            border-radius: 4px;
        }
    }
    
    .item-info{
        padding-left: 60px;
        padding-right: 60px;

        @include mq(false, lg) {
            margin-top: 30px;
            padding-left: 0;
            padding-right: 0;
        }

        .google-rev{
            margin-bottom: 16px;
        }
        .text{
            margin-top: 16px;
            p{
                opacity: .6;
            }
        }

        .btn-outline{
            margin-top: 16px;
        }

    }

    // @include mq(false, lg) {
    //     gap: 30px;
    // }
}


