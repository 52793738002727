.search{
    &-input{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        background: #FFF;
        padding-right: 16px;
        input{
            height: 52px;
            padding: 0 16px;
            font-family: $secondary-font;
            font-size: 18px;
            font-weight: 600;
            color: #000;
            &::placeholder{
                color: #000;
                opacity: .3;
            }
            &:focus{
                caret-color: $accent;
            }
        }
        .icon-search{
            height: 24px;
            width: 24px;
            stroke: $black;
            opacity: .5;
            stroke-width: 1.5px;
        }
    }

    &-input:focus-within{
        .icon-search {
            opacity: 1; 
        }
    }

    &-close{
        display: flex;
        width: 21px;
        height: 21px;
        padding: 3px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.50);
        .icon{
            padding-bottom: 1px;
            height: 12px;
            width: 12px;
            stroke: #fff;
            stroke-width: 1.5px;
        }
    }

    &.-tel{
        opacity: 0;
        display: none;
        align-items: center;
        position: absolute; 
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $black;
        z-index: 8889;
        padding-left: 15px;
        padding-right: 15px;

        &.active{
            opacity: 1;
            display: flex;
        }
        
        .search-input{
            width: 100%;
            .input{
                width: 100%;
                font-size: 16px;
                padding-left: 0 16px;
            }
        }
    }
}

