.google-rev{
    display: flex;
    align-items: center;
    gap: 4px;
    
    &:hover{
        @include hover-opacity;
    }

    &.-black-text{
        *{
            color: $black;
        }
    }

    .icon-google{
        height: 14px;
        width: 14px;
    }

    .icon-star{
        height: 16px;
        width: 16px;
    }

    &-rating{
        font-size: 13px;
        letter-spacing: 0.2px;
        color: #fff;
    }

    &-amount{
        font-size: 13px;
        letter-spacing: 0.2px;
        color: #fff;
        opacity: .5;

        @include mq(false, md) {
            display: none;
        }
    }
}