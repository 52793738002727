.filter{
    border-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    &.active{
        .filter-head{
            .icon{
                transform: rotate(180deg);
            }
        }
    }

    &-checkbox{
        margin: 12px 0;
        display: flex;
        align-items: center;
        gap: 10px;
        label{
            font-family: $secondary-font;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
        }
        & input:checked + label {
            font-weight: 500; 
        }
    }

   

    &-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 4px;
        cursor: pointer;
        span{
            color: #000;
            font-family: $secondary-font;
            font-size: 18px;
            font-weight: 400;
        }
        .icon{
            height: 20px;
            width: 20px;
            stroke-width: 1.5px;
            stroke: $accent;
            transform: rotate(0);
        }
    }
    // @include mq(false, lg) {
    //     gap: 30px;
    // }
}


