.contact-block{
    background: #000;
    padding: 48px 0;
    h2{
        font-size: 30px;
        color: #fff;
        margin-bottom: 32px;
    }
    &-btn{
        display: flex;
        gap: 16px;
        a{
            width: 50%;
        }
    }
    .btn-light{
        background: #F4F5F6;
    }
    // @include mq(false, lg) {
    //     gap: 30px;
    // }
}


