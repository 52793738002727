.line-whatsapp{
    padding: 48px 0;
    .container{
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 16px;
        @include mq(false, md) {
            flex-direction: column;
            gap: 0;
            align-items: start;
        }
    }
    
    p{
        font-family: $secondary-font;
        font-size: 16px;
        margin: 0;
    }
}