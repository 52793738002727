.footer{
    padding-top: 48px;
    background: #F4F5F6;
    &-row01{
        display: flex;
        justify-content: space-between;

        @include mq(false, lg) {
            flex-direction: column;
            gap: 48px;
        }

        img{
            margin-bottom: 25px;
        }
        .social{
            margin-top: 32px;
        }
        .item-nav{
            display: flex;
            gap: 48px;
            
            @include mq(false, lg) {
                flex-direction: column;
                gap: 38px;
            }
        }
    }

    &-nav{
        ul{
            min-width: 150px;

            @include mq(false, lg) {
                min-width: none;
            }
            
            li{
                margin: 8px 0;
                a{
                    display: block;
                    font-size: 14px;
                    color: $black;
                    opacity: .8;
                    &:hover{
                        color: $accent;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-nav.-col{
        max-width: 300px;

        @include mq(false, lg) {
            max-width: 100%;
        }

        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                width: 50%;
            }
        }
    }

    &-nav-title{
        display: inline-block;
        width: 100%;
        font-size: 16px;
        margin-bottom: 10px;
    }

    &-logo{
        img{
            max-width: 114px;
            max-height: 42px;
        }
    }

    &-row02{
        margin-top: 32px;
        margin-bottom: 32px;
        padding: 13px 0;
        display: flex;
        justify-content: space-between;

        @include mq(false, lg) {
            flex-direction: column;
            gap: 38px;
            
            .item:last-child{
                flex-direction: column;
            }
        }

        .item{
            display: flex;
            flex-wrap: wrap;
            gap: 24px; 
        }

        a, span{
            display: inline-flex;
            align-items: center;
            gap: 4px;
            color: #000;
            font-family: $secondary-font;
            font-size: 14px;
            font-weight: 400;
            flex-shrink: 0;
        }
        .icon-support{
            height: 16px;
            width: 16px;
            stroke: $black;
        }
        a:hover{
            color: $accent;
            .icon-support{
                stroke: $accent;
            }
        }
        .icon-whatsapp{
            height: 34px;
            width: 34px;
            &:hover{
                opacity: .85;
            }
        }
    }

    &-row03{
        padding: 32px 0;
        display: flex;
        justify-content: space-between;

        @include mq(false, lg) {
            padding-top: 20px;
            flex-direction: column;
            gap: 32px;
        }

        .item{
            display: flex;
            align-items: center;
            gap: 24px;

            @include mq(false, lg) {
                justify-content: space-between;
            }
        }
        p{
            font-family: $secondary-font;
            font-size: 14px;
            color: #7A7B7B;
            margin: 0 !important;
        }
        a{
            color: $accent;
            text-decoration: none;
        }
        .btn-top{
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            border: 2px solid #000;
            opacity: .5;
            .icon{
                height: 20px;
                width: 20px;
                stroke: #000;
                stroke-width: 1.5px;
            }
            &:hover{
                opacity: 1;
            }
        }
    }
}
