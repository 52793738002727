.category-img{
    padding-bottom: 72px;
    .container{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        justify-content: center;

        @include mq(false, xl) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include mq(false, lg) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mq(false, md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include mq(false, sm) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .item{
        height: 100%;
    }

    &-box{
        position: relative;
        display: flex;
        height: 100%;
        padding: 24px 16px 16px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: #FFF;
        cursor: pointer;
        // &:hover{
        //     .category-img-hover{
        //         opacity: 1;
        //     }
        // }
        // .category-img-hover:hover{
        //     background: red;
        //     opacity: 1 !important;
        // } 
    }

    &-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 114px;
        img{
            max-width: 100%;
            max-height: 114px;
        }
    }

    &-title{
        font-size: 21px;
        text-transform: uppercase;
    }

    &-hover{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 24px;
        background: $black;
        z-index: 88;
        border-radius: 4px;
        opacity: 0;
        ul{
            margin-top: 17px;
            li:first-child{
                margin-top: 0;
            }
            li:last-child{
                margin-bottom: 0;
            }
            li{
                margin: 4px 0;
            }
        }
    }

    .category-img-hover:hover{
        opacity: 1;
        z-index: 99;
    }

    &-link{
        display: flex;
        align-items: center;
        color: #F4F5F6;
        font-family: $secondary-font;
        font-size: 18px;
        font-weight: 600;
        gap: 2px;
        .icon{
            height: 22px;
            width: 22px;
            stroke: $accent;
            stroke-width: 1.5px;
        }
        &:hover{
            color: $accent;
        }
    }

}