.social{
    display: flex;
    gap: 16px;
    &-link{
        display: flex;

        .icon{
            height: 24px;
            width: 24px;
            fill: #BCBCBC;
            &:hover{
                fill: $accent;
            }
        }

        .icon-instagram{
            stroke: #BCBCBC;
            stroke-width: 1.5px;
            fill: none;
            &:hover{
                fill: none;
                stroke: $accent;
            }
        }
    }
}