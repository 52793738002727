.contact-items{
    display: flex;
    flex-direction: column;
    gap: 12px;
    &.-hr{
        flex-direction: row;
        gap: 32px;
        @include mq(false, sm) {
            gap: 15px;
            flex-direction: column;
        }
    }
}

.contact-item{
    display: flex;
    align-items: center;
    gap: 4px;
    color: $black;
    font-size: 14px;
    letter-spacing: 0.28px;
    
    .icon{
        flex-shrink: 0;
        height: 24px;
        width: 24px;
    }

    &.-gmap{
        margin-top: 12px;
        color: $accent;
        .icon{
            height: 20px;
            width: 20px;
        }
    }
}

a.contact-item:hover{
    color: $accent;
}

.link-whatsapp{
    display: flex;
    gap: 6px;
    align-items: center;
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    .icon{
        height: 18px;
        width: 18px;
    }
    &:hover{
        @include hover-opacity;
    }
}