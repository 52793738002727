.slider-pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 40px;

    @include mq(false, md) {
        margin-top: 30px;
    }

    .swiper-pagination-bullet{
        --size: 10px;
        width: var(--size);
        height: var(--size);
        min-width: var(--size);
        border-radius: 50px;
        background: $accent;
        cursor: pointer;
        transition: 0.2s;
        opacity: 0.5;

        &-active{
            opacity: 1;
        }
    }
}