@charset "UTF-8";

/* Utils */
@import "./utils/mixins";
@import "./utils/variables";
@import "./utils/mq";

/* Vendor */
@import "bootstrap/scss/bootstrap-grid";
@import "swiper/swiper";

/* Base */
@import "./base/reset";
@import "./base/fonts";
@import "./base/global";

/* Components */
@import "./components/btn";
@import "./components/google-rev";
@import "./components/search";
@import "./components/slider-button";
@import "./components/slider-pagination";
@import "./components/contact-items";
@import "./components/social";
@import "./components/breadcrumbs";
@import "./components/pagination";
@import "./components/product-card";
@import "./components/filter";
@import "./components/articul";
@import "./components/form";

/* Layout */
@import "./layout/header";
@import "./layout/menu-tel";
@import "./layout/footer";
@import "./layout/hero";
@import "./layout/line-icons";
@import "./layout/line-whatsapp";
@import "./layout/category-text";
@import "./layout/category-img";
@import "./layout/text-img";
@import "./layout/page-head";
@import "./layout/subheader";
@import "./layout/catalog-cards";
@import "./layout/contact-block";
@import "./layout/pages";
@import "./layout/product-single";
@import "./layout/reviews";