.reviews{
    padding: 112px 0;
    &-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        margin-bottom: 48px;
        .google-rev{
            *{
                color: $black;
            }
        }
    }
    &-row{
        display: grid;
        grid-template-rows: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);
        @include mq(false, lg) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &-btn{
        margin-top: 48px;
        display: flex;
        justify-content: end;
        
        .btn-light{
            background: #fff;
            border: 1px solid #fff;
            &:hover{
                opacity: .8;
            }
            @include mq(false, md) {
                width: 100%;
            }
        }
        .icon-google{
            stroke: none;
        }
        .icon-arrow-link{
            height: 24px;
            width: 24px;
            stroke-width: 1.5px;
        }
    }
    .item{
        display: flex;
        height: 100%;
        padding: 32px;
        flex-direction: column;
        gap: 20px;
        border-radius: 12px;
        background: #FFF;
        &-img{
            height: 48px;
            width: 48px;
            border-radius: 50px;
            background-size: cover;
            background-position: center;
        }
        &-head{
            display: flex;
            align-items: center;
            gap: 16px;
        }
        &-name{
            color: #2C2C2C;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.16px;
        }
        &-date{
            color: $black;
            font-size: 16px;
            font-weight: 400;
            opacity: .5;
        }
        &-stars{
            display: flex;
            .icon{
                height: 20px;
                width: 20px;
            }
        }
    }
}


