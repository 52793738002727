.page-category{
    .page-head .item-end{
        @include mq(false, lg) {
            justify-content: start;
            width: 100%;
        }
    }
    .page-head .container {
        row-gap: 10px;
    }
    
}


