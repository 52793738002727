.pagination{
    display: flex;
    align-items: center;
    gap: 32px;
    @include mq(false, sm) {
        gap: 10px;
    }
    ul{
        li:first-child,
        li:last-child{
            margin: 0;
        }
        li{
            display: inline-block;
            margin: 0 4px;
            a{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                color: #000;
                opacity: .5;
            }
            a:hover{
                background: $accent;
                opacity: 1;
                color: #F4F5F6;
                border: 1px solid $accent;
            }
            &.active{
                a{
                    background: $black;
                    color: #F4F5F6;
                    opacity: 1;
                    font-weight: 700;
                    border: 1px solid $black;
                }
            }
        }
    }
    .icon{
        stroke: $black;
        stroke-width: 1.5px;
        height: 20px;
        width: 20px;
        opacity: .5;
    }
    &-btn{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        gap: 8px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        border-radius: 4px;
        min-width: 40px;
        span{
            color: #000;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.16px;
            opacity: 0.5;
        }
        &.-next{
            padding-left: 16px;
            padding-right: 8px;
        }
        &.-prev{
            padding-left: 8px;
            padding-right: 16px;
            .icon{
                transform: rotate(-180deg);
            }
        }
        &.-next,
        &.-prev{
            @include mq(false, sm) {
                padding: 0;
                span{
                    display: none;
                }
            }
        }
        &.-next:hover,
        &.-prev:hover{
            background: #fff;
        }
    }
    
    // @include mq(false, lg) {
    //     gap: 30px;
    // }
}


